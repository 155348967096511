import { Link } from "gatsby";
import * as React from "react";
import { getTheme } from "../functions/theme";
import { SEO } from "../components/seo";
import { getUserPreferredLocale } from "../functions/utils";

const NotFoundPage = () => {

  const currentTheme: 'light' | 'dark' = getTheme();
  // const _userLocale: string = getUserPreferredLocale();
    
  return (
    <div className={`flex flex-col justify-center lg:pb-0 pb-16 px-5 lg:px-36 w-screen h-screen ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
      <span className='text-5xl uppercase lg:text-9xl'>error 404</span>
      <span className='px-1 text-base lg:px-3 lg:text-xl'>Page not found</span>
      <span className='px-1 mt-10 text-lg lg:text-2xl lg:px-3'>We're sorry, the page you requested could not be found. Please go back to the homepage or an avaliable page!</span>
      <div className='fixed right-0 flex justify-end w-full pr-5 lg:pr-36 bottom-10'>
        <Link to='/'>
          <div className='flex flex-row items-center group'>
            <svg xmlns="http://www.w3.org/2000/svg" width="56px" height="56px" viewBox="0 0 130 130" >
                <g id="Raggruppa_539" data-name="Raggruppa 539" transform="translate(-355 -624)">
                    <path id="Tracciato_286" className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} fill="#f5f4f5" data-name="Tracciato 286" d="M441.857,645.444h-43.91A19.756,19.756,0,0,0,378.2,665.2v43.909a19.752,19.752,0,0,0,19.752,19.753h43.91A19.751,19.751,0,0,0,461.6,709.106V665.2A19.755,19.755,0,0,0,441.857,645.444Zm-38.1,50.675a10.009,10.009,0,1,1,10.009-10.009A10.009,10.009,0,0,1,403.756,696.119Zm10.009-27.31H393.747V664.2h20.018Zm32.666,24-3.85,3.765-6.323-6.471-6.324,6.471-3.85-3.765,6.409-6.561-6.409-6.562,3.85-3.759,6.324,6.471,6.323-6.471,3.85,3.759-6.409,6.562Z"/>
                </g>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="177.845" height="15.948" viewBox="0 0 177.845 15.948" className="logo-to-reverse" id="logo-svg">
              <g id="Raggruppa_21" data-name="Raggruppa 21" transform="translate(0 0)">
                <g id="Raggruppa_19" data-name="Raggruppa 19" transform="translate(0 0)" clip-path="url(#clip-path)">
                  <path id="Tracciato_20"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 20" d="M4.2,15.8a3.971,3.971,0,0,1-2.544-.831A2.488,2.488,0,0,1,.6,12.954V3.478A2.488,2.488,0,0,1,1.657,1.466,3.978,3.978,0,0,1,4.2.635L18.677.646V3.193L4.761,3.183a1.8,1.8,0,0,0-.813.105c-.1.07-.146.28-.146.631v8.592c0,.351.048.562.146.631a1.8,1.8,0,0,0,.813.105l13.916.011v2.548Z" transform="translate(-0.225 -0.237)" fill="#F5F4F5"/>
                  <path id="Tracciato_21"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 21" d="M18.83,15.961,3.976,15.95A4.354,4.354,0,0,1,1.2,15.037,2.857,2.857,0,0,1,0,12.73V3.254A2.858,2.858,0,0,1,1.2.946,4.363,4.363,0,0,1,3.976.033L18.83.044v3.3L4.536,3.337a3.192,3.192,0,0,0-.557.034,1.653,1.653,0,0,0-.024.326v8.591a1.716,1.716,0,0,0,.024.329,3.727,3.727,0,0,0,.557.031l14.294.011ZM3.976.789a3.574,3.574,0,0,0-2.31.75A2.111,2.111,0,0,0,.755,3.254V12.73a2.112,2.112,0,0,0,.91,1.714,3.572,3.572,0,0,0,2.31.751l14.1.01V13.413L4.536,13.4c-.693,0-.907-.085-1.034-.176a1.118,1.118,0,0,1-.3-.939V3.7a1.118,1.118,0,0,1,.3-.939c.127-.092.341-.176,1.033-.176l13.539.01V.8Z" transform="translate(0 -0.012)" fill="#F5F4F5"/>
                  <path id="Tracciato_22"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 22" d="M37.507,15.8V3.478a2.486,2.486,0,0,1,1.052-2.011A3.987,3.987,0,0,1,41.106.635H53.1a3.988,3.988,0,0,1,2.547.832A2.492,2.492,0,0,1,56.7,3.478V15.8H53.473V10.6H40.7v5.2Zm3.2-7.728H53.473V3.92q0-.527-.146-.632a1.806,1.806,0,0,0-.814-.105H41.666a1.806,1.806,0,0,0-.814.105c-.1.07-.147.28-.147.632Z" transform="translate(-13.973 -0.237)" fill="#F5F4F5"/>
                  <path id="Tracciato_23"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 23" d="M56.852,15.95H52.87v-5.2H40.858v5.2H36.9V3.254A2.855,2.855,0,0,1,38.1.946,4.37,4.37,0,0,1,40.882.033H52.875a4.37,4.37,0,0,1,2.78.913,2.86,2.86,0,0,1,1.2,2.308Zm-3.226-.756H56.1V3.254a2.111,2.111,0,0,0-.909-1.714A3.586,3.586,0,0,0,52.875.788H40.882a3.587,3.587,0,0,0-2.313.751,2.109,2.109,0,0,0-.908,1.714v11.94H40.1v-5.2H53.626Zm0-6.972H40.1V3.7a1.139,1.139,0,0,1,.3-.939c.128-.091.342-.176,1.034-.176H52.29c.692,0,.906.085,1.033.176a1.121,1.121,0,0,1,.3.939ZM40.858,7.466H52.87V3.7a1.647,1.647,0,0,0-.024-.326,3.125,3.125,0,0,0-.557-.034H41.441a3.126,3.126,0,0,0-.558.034,1.613,1.613,0,0,0-.025.326Z" transform="translate(-13.749 -0.012)" fill="#F5F4F5"/>
                  <path id="Tracciato_24"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 24" d="M78.332,15.809V.668H93.924a3.955,3.955,0,0,1,2.547.843,2.5,2.5,0,0,1,1.052,2V7.68a2.486,2.486,0,0,1-1.052,1.98,3.954,3.954,0,0,1-2.547.842H92.432l5.039,4.717v.59h-3.6L88.247,10.5H81.529v5.306ZM82.49,7.954H93.338a1.711,1.711,0,0,0,.8-.1q.133-.105.132-.632V3.954c0-.351-.044-.566-.132-.643a1.58,1.58,0,0,0-.8-.115H82.49a1.665,1.665,0,0,0-.813.115c-.1.077-.148.292-.148.643V7.217c0,.351.05.562.148.632a1.8,1.8,0,0,0,.813.1" transform="translate(-29.182 -0.249)" fill="#F5F4F5"/>
                  <path id="Tracciato_25"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 25" d="M97.625,15.963H93.5l-5.625-5.306h-6.19v5.306H77.73V.066H93.7a4.344,4.344,0,0,1,2.782.925,2.873,2.873,0,0,1,1.195,2.3v4.17a2.854,2.854,0,0,1-1.194,2.275,4.341,4.341,0,0,1-2.783.925h-.535l4.46,4.174ZM93.8,15.207h3.071v-.048L91.251,9.9H93.7a3.558,3.558,0,0,0,2.311-.76,2.108,2.108,0,0,0,.91-1.684V3.287a2.129,2.129,0,0,0-.91-1.706A3.559,3.559,0,0,0,93.7.822H78.486V15.207h2.441V9.9h7.246Zm-.683-7.1H82.267c-.658,0-.89-.071-1.035-.177a1.134,1.134,0,0,1-.3-.937V3.73c0-.49.09-.779.291-.939a1.732,1.732,0,0,1,1.048-.2H93.115c.67,0,.9.082,1.047.207.185.162.264.439.264.929V6.993c0,.505-.08.774-.277.929-.143.114-.367.186-1.033.186M81.708,7.319a3.29,3.29,0,0,0,.558.033H93.115a3.413,3.413,0,0,0,.533-.029,1.953,1.953,0,0,0,.021-.329V3.73a1.824,1.824,0,0,0-.025-.348,3.028,3.028,0,0,0-.53-.033H82.267a2.79,2.79,0,0,0-.555.038,1.528,1.528,0,0,0-.028.343V6.993a1.623,1.623,0,0,0,.024.326" transform="translate(-28.958 -0.025)" fill="#F5F4F5"/>
                  <path id="Tracciato_26"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 26" d="M118.946,15.8V.635h15.592a3.99,3.99,0,0,1,2.547.831,2.491,2.491,0,0,1,1.052,2.012v9.476a2.493,2.493,0,0,1-1.052,2.012,3.983,3.983,0,0,1-2.547.831Zm4.158-2.548h10.848a1.8,1.8,0,0,0,.813-.105c.1-.07.146-.28.146-.631V3.92c0-.351-.048-.561-.146-.631a1.8,1.8,0,0,0-.813-.105H123.1a1.8,1.8,0,0,0-.813.105q-.147.105-.147.631v8.592q0,.526.147.631a1.8,1.8,0,0,0,.813.105" transform="translate(-44.313 -0.237)" fill="#F5F4F5"/>
                  <path id="Tracciato_27"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 27" d="M134.314,15.95h-15.97V.033h15.97a4.374,4.374,0,0,1,2.781.913,2.863,2.863,0,0,1,1.2,2.308V12.73a2.862,2.862,0,0,1-1.195,2.307,4.37,4.37,0,0,1-2.782.914M119.1,15.194h15.214a3.586,3.586,0,0,0,2.313-.75,2.114,2.114,0,0,0,.908-1.714V3.254a2.115,2.115,0,0,0-.909-1.715,3.59,3.59,0,0,0-2.312-.75H119.1ZM133.729,13.4H122.88c-.693,0-.906-.085-1.033-.177-.213-.151-.3-.432-.3-.939V3.7c0-.506.092-.786.306-.94.127-.09.34-.176,1.033-.176h10.849c.691,0,.9.085,1.033.176a1.123,1.123,0,0,1,.3.94v8.591a1.116,1.116,0,0,1-.3.94c-.127.09-.341.176-1.032.176m-11.407-.79a3.13,3.13,0,0,0,.558.034h10.849a3.119,3.119,0,0,0,.557-.034,1.681,1.681,0,0,0,.024-.326V3.7a1.691,1.691,0,0,0-.024-.329,3.72,3.72,0,0,0-.557-.031H122.88a3.188,3.188,0,0,0-.558.034,1.561,1.561,0,0,0-.025.326v8.591a1.627,1.627,0,0,0,.024.326" transform="translate(-44.088 -0.012)" fill="#F5F4F5"/>
                  <rect id="Rettangolo_74" className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`}  data-name="Rettangolo 74" width="3.171" height="15.162" transform="translate(100.112 0.398)" fill="#F5F4F5"/>
                  <path id="Tracciato_28"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 28" d="M162.878,15.95h-3.927V.033h3.927Zm-3.171-.756h2.416V.788h-2.416Z" transform="translate(-59.216 -0.012)" fill="#F5F4F5"/>
                  <path id="Tracciato_29"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 29" d="M174.249,15.8V.635H177.9l12.314,11.582V.635h3.223V15.8h-3.65l-12.341-11.6V15.8Z" transform="translate(-64.916 -0.237)" fill="#F5F4F5"/>
                  <path id="Tracciato_30"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 30" d="M193.593,15.95h-4.178L177.6,4.843V15.95h-3.955V.033h4.18l11.787,11.086V.033h3.979Zm-3.878-.755h3.123V.788H190.37V12.866L177.528.788H174.4V15.194h2.443V3.1Z" transform="translate(-64.691 -0.012)" fill="#F5F4F5"/>
                  <path id="Tracciato_31"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 31" d="M215.116,15.8V3.478a2.486,2.486,0,0,1,1.052-2.011,3.987,3.987,0,0,1,2.547-.832h11.993a3.988,3.988,0,0,1,2.547.832,2.492,2.492,0,0,1,1.052,2.011V15.8h-3.225V10.6H218.314v5.2Zm3.2-7.728h12.768V3.92q0-.527-.146-.632a1.806,1.806,0,0,0-.814-.105H219.274a1.8,1.8,0,0,0-.813.105c-.1.07-.147.28-.147.632Z" transform="translate(-80.14 -0.237)" fill="#F5F4F5"/>
                  <path id="Tracciato_32"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 32" d="M234.46,15.95h-3.982v-5.2H218.467v5.2h-3.954V3.254a2.855,2.855,0,0,1,1.2-2.308,4.366,4.366,0,0,1,2.78-.913h11.994a4.367,4.367,0,0,1,2.78.913,2.859,2.859,0,0,1,1.2,2.308Zm-3.226-.756h2.47V3.254a2.113,2.113,0,0,0-.91-1.714,3.582,3.582,0,0,0-2.312-.751H218.489a3.587,3.587,0,0,0-2.312.751,2.11,2.11,0,0,0-.909,1.714v11.94h2.443v-5.2h13.523Zm0-6.972H217.711V3.7c0-.506.091-.786.3-.94.127-.09.341-.176,1.034-.176H229.9c.693,0,.907.085,1.034.176a1.119,1.119,0,0,1,.3.939Zm-12.767-.755h12.011V3.7a1.714,1.714,0,0,0-.023-.326,3.142,3.142,0,0,0-.558-.034H219.05a3.12,3.12,0,0,0-.558.034,1.556,1.556,0,0,0-.025.326Z" transform="translate(-79.916 -0.012)" fill="#F5F4F5"/>
                  <path id="Tracciato_33"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 33" d="M256.024,15.784V.6h3.2V13.236h13.625v2.548Z" transform="translate(-95.38 -0.224)" fill="#F5F4F5"/>
                  <path id="Tracciato_34"  className={`duration-300 ${currentTheme === 'light' ? 'fill-black' : 'fill-cardinal-white'} group-hover:fill-cardinal`} data-name="Tracciato 34" d="M273,15.937H255.422V0h3.955V12.634H273Zm-16.823-.755h16.068V13.39H258.621V.755h-2.444Z" transform="translate(-95.156 0)" fill="#F5F4F5"/>
                </g>
              </g>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage;

export const Head = () => {
  return (
    <SEO 
      title="Cardinal - Error 404"
      description="Cardinal error 404 page not found"
      pathname="/404"
      preventIndexing={true} />
  )
}